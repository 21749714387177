import product1 from '../../images/MakkanKarhai.jpg';
import product2 from '../../images/MuttonHandi.jpg';
import product3 from '../../images/BBQ.jpg';
import product4 from '../../images/WhiteQorma.jpg';
import product5 from '../../images/MuttonKima.jpg';
import product6 from '../../images/AluParatha.jpg';
import product7 from '../../images/ChickenCheeseNan.jpg';
import product8 from '../../images/Haleem.jpg';
import product9 from '../../images/Omellete.jpg';
import sweet1 from '../../images/Rasgulla.jpg';
import sweet2 from '../../images/GulabJaman.jpg';
import sweet3 from '../../images/Barfi.jpg';
import sweet4 from '../../images/GajjarHalwa.jpg';
import sweet5 from '../../images/Kheer.jpg';
import sweet6 from '../../images/Custard.jpg';

export const productData = [
  {
    img: product2,
    alt: 'Mutton karahi',
    name: 'Mutton karahi',
    desc: 'A savory Pakistani dish with mutton cooked in a karahi (wok) with aromatic spices.',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: product3,
    alt: 'BBQ',
    name: 'BBQ',
    desc: 'Exquisite grilled & barbecued meats, marinated to perfection, served with flavorful sauces.',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: product1,
    alt: 'Mutton Handi',
    name: 'Mutton Handi',
    desc: 'A flavorful Pakistani dish with mutton cooked in a traditional handi (clay pot).',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: product4,
    alt: 'Mutton white korma',
    name: 'Mutton white korma',
    desc: 'A creamy and mild Pakistani dish with mutton cooked in a white korma sauce.',
    price: '20.0 AED',
    button: 'Order Now',
  },
  {
    img: product5,
    alt: 'Mutton keema',
    name: 'Mutton keema',
    desc: 'A delicious Pakistani dish made with minced mutton and aromatic spices.',
    price: '10.0 AED',
    button: 'Order Now',
  },
  {
    img: product6,
    alt: 'Alu Pratha',
    name: 'Alu Pratha',
    desc: 'A delicious Pakistani flatbread stuffed with spiced potato filling.',
    price: '3.00 AED',
    button: 'Order Now',
  },
  {
    img: product7,
    alt: 'Chiken Cheez Nan',
    name: 'Chiken Cheez Nan',
    desc: 'A scrumptious Pakistani nan bread with chicken and cheese filling.',
    price: '8.0 AED',
    button: 'Order Now',
  },
  {
    img: product8,
    alt: 'Haleem',
    name: 'Haleem',
    desc: 'A popular Pakistani dish made with meat, lentils, and wheat, slow-cooked to perfection.',
    price: '10.0 AED',
    button: 'Order Now',
  },
  {
    img: product9,
    alt: 'Egg Omelette',
    name: 'Egg Omelette',
    desc: 'A classic omelette made with eggs and your choice of fillings, popular in Pakistan.',
    price: '2.0 AED',
    button: 'Order Now',
  },
];
export const DinnerAndLunchItems = [
  {
    img: 'product1.jpg',
    alt: 'Mutton karahi',
    name: 'Mutton karahi',
    desc: 'A savory Pakistani dish with mutton cooked in a karahi (wok) with aromatic spices.',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product2.jpg',
    alt: 'Mutton Handi',
    name: 'Mutton Handi',
    desc: 'A flavorful Pakistani dish with mutton cooked in a traditional handi (clay pot).',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product3.jpg',
    alt: 'Mutton Achari',
    name: 'Mutton Achari',
    desc: 'A tangy and spicy Pakistani dish with mutton marinated in pickling spices.',
    price: '18.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product4.jpg',
    alt: 'Mutton korma',
    name: 'Mutton korma',
    desc: 'A rich and creamy Pakistani dish with mutton cooked in a flavorful korma sauce.',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product5.jpg',
    alt: 'Mutton tawwa champ',
    name: 'Mutton tawwa champ',
    desc: 'Tender and succulent mutton chops cooked to perfection on a tawwa (griddle).',
    price: '18.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product6.jpg',
    alt: 'Mutton keema',
    name: 'Mutton keema',
    desc: 'A delicious Pakistani dish made with minced mutton and aromatic spices.',
    price: '10.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product7.jpg',
    alt: 'Mutton chop handi',
    name: 'Mutton chop handi',
    desc: 'A delightful dish with mutton chops cooked in a handi (clay pot) with spices.',
    price: '20.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product8.jpg',
    alt: 'Mutton white korma',
    name: 'Mutton white korma',
    desc: 'A creamy and mild Pakistani dish with mutton cooked in a white korma sauce.',
    price: '20.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product9.jpg',
    alt: 'Mutton pashawri',
    name: 'Mutton pashawri',
    desc: 'A traditional Pakistani dish with mutton cooked in a Peshawari style.',
    price: '18.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product10.jpg',
    alt: 'Mutton phindi',
    name: 'Mutton phindi',
    desc: 'A flavorful Pakistani dish with mutton cooked with lotus stem (phindi) and spices.',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product11.jpg',
    alt: 'Mutton karyla',
    name: 'Mutton karyla',
    desc: 'A delicious Pakistani dish with mutton and bitter gourd (karyla) cooked together.',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product12.jpg',
    alt: 'Mutton makhni',
    name: 'Mutton makhni',
    desc: 'A rich and creamy dish with mutton cooked in a buttery tomato sauce.',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    img: 'product13.jpg',
    alt: 'Mutton dall mix',
    name: 'Mutton dall mix',
    desc: 'A flavorful Pakistani dish with mutton and mixed lentils cooked together.',
    price: '15.0 AED',
    button: 'Order Now',
  },
  {
    name: 'Chicken Karahi',
    desc: 'Spicy chicken curry cooked in a karahi, a traditional Pakistani wok',
    price: '10.0 AED',
  },
  {
    name: 'Chicken Korma',
    desc: 'Creamy chicken curry with rich flavors and spices',
    price: '10.0 AED',
  },
  {
    name: 'Chicken Tawa',
    desc: 'Chicken cooked on a flat griddle with a blend of spices',
    price: '15.0 AED',
  },
  {
    name: 'Chicken Green',
    desc: 'Chicken curry made with green herbs and spices',
    price: '15.0 AED',
  },
  {
    name: 'Chicken Jalfrezi',
    desc: 'Spicy chicken stir-fry with assorted vegetables',
    price: '15.0 AED',
  },
  {
    name: 'Chicken White Karahi',
    desc: 'Creamy white chicken curry cooked in a karahi',
    price: 'Best Buy',
  },
  {
    name: 'Chicken Achari',
    desc: 'Chicken curry with a tangy pickle-like flavor',
    price: '12.0 AED',
  },
  {
    name: 'Chicken Tikka Karahi',
    desc: 'Chicken tikka cooked in a karahi with spices',
    price: '20.0 AED',
  },
  {
    name: 'Chicken Ginger',
    desc: 'Chicken curry with prominent ginger flavor',
    price: '15.0 AED',
  },
  {
    name: 'Peshawari Karahi',
    desc: 'Special chicken karahi with Peshawari spices and flavors',
    price: 'Best Buy',
  },
  {
    name: 'Chicken Black Pepper',
    desc: 'Chicken curry seasoned with black pepper',
    price: 'Best Buy',
  },
  {
    name: 'Chicken Makhni',
    desc: 'Butter chicken with a creamy tomato-based sauce',
    price: '18.0 AED',
  },
];

export const SweetsItems = [
  {
    img: 'sweets1.jpg',
    alt: 'Rasgula',
    name: 'Rasgula',
    desc: 'A spongy and sweet dessert made from cottage cheese balls soaked in sugar syrup.',
    price: '1.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets2.jpg',
    alt: 'Gulabjaman',
    name: 'Gulabjaman',
    desc: 'Delicious deep-fried dumplings soaked in fragrant sugar syrup.',
    price: '1.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets3.jpg',
    alt: 'Barfi',
    name: 'Barfi',
    desc: 'A rich and creamy fudge-like sweet made with condensed milk and various flavors.',
    price: '1.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets4.jpg',
    alt: 'Lado',
    name: 'Lado',
    desc: 'Traditional round-shaped sweet made with roasted flour, ghee, and sugar.',
    price: '1.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets5.jpg',
    alt: 'Patisa',
    name: 'Patisa',
    desc: 'A flaky and crispy sweet made with layers of sugar-coated dough.',
    price: '1.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets6.jpg',
    alt: 'Jalebi',
    name: 'Jalebi',
    desc: 'Orange spiral-shaped sweet made from deep-fried batter soaked in sugar syrup.',
    price: '3.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets7.jpg',
    alt: 'Gajjar Halwa',
    name: 'Gajjar Halwa',
    desc: 'A delectable sweet dish made from grated carrots cooked in milk and sugar.',
    price: '10.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets8.jpg',
    alt: 'Rasmalai',
    name: 'Rasmalai',
    desc: 'Soft and spongy cottage cheese dumplings served in a creamy milk syrup.',
    price: '5.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets9.jpg',
    alt: 'Falooda',
    name: 'Falooda',
    desc: 'A refreshing dessert with vermicelli, basil seeds, ice cream, and flavored milk.',
    price: '8.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets10.jpg',
    alt: 'Kheer',
    name: 'Kheer',
    desc: 'A classic rice pudding made with milk, rice, and sweetened with sugar or jaggery.',
    price: '5.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets11.jpg',
    alt: 'Custard',
    name: 'Custard',
    desc: 'A creamy and smooth dessert made with milk, eggs, sugar, and flavored with vanilla.',
    price: '5.0 AED',
    button: 'Order Now',
  },
  {
    img: 'sweets12.jpg',
    alt: 'Fruit Chat',
    name: 'Fruit Chat',
    desc: 'A refreshing fruit salad mixed with spices and tangy chutneys.',
    price: '5.0 AED',
    button: 'Order Now',
  },
  {
    name: 'Papri Chana Chaat',
    desc: 'Papri Chana Chaat is a popular Indian street food that combines crispy papri, tender chickpeas, and a mix of spices and chutneys.',
    price: '5.0 AED',
  },
  {
    name: 'Dahi Puri',
    desc: 'Dahi Puri, a beloved Indian street food, is a delightful concoction of small, crispy puris filled with a mixture of spiced potatoes, chickpeas, and tangy tamarind chutney.',
    price: '5.0 AED',
  },
];

export const BreakFastItems = [
  {
    img: 'product3',
    alt: 'Alu Pratha',
    name: 'Alu Pratha',
    desc: 'A delicious Pakistani flatbread stuffed with spiced potato filling.',
    price: '3.00 AED',
  },
  {
    img: 'product3',
    alt: 'Sada Pratha',
    name: 'Sada Pratha',
    desc: 'A plain and simple Pakistani flatbread, best served with curries and chutneys.',
    price: '1.00 AED',
  },
  {
    img: 'product3',
    alt: 'Muli Pratha',
    name: 'Muli Pratha',
    desc: 'A flavorful Pakistani flatbread stuffed with seasoned radish.',
    price: '3.5 AED',
  },
  {
    img: 'product3',
    alt: 'Methi Pratha',
    name: 'Methi Pratha',
    desc: 'A Pakistani flatbread made with fenugreek leaves, rich in flavor.',
    price: '4.00 AED',
  },
  {
    img: 'product3',
    alt: 'Ghobi Pratha',
    name: 'Ghobi Pratha',
    desc: 'A Pakistani flatbread filled with spiced cauliflower, a delightful choice.',
    price: '3.5 AED',
  },
  {
    img: 'product3',
    alt: 'Sada Nan',
    name: 'Sada Nan',
    desc: 'A simple and soft Pakistani leavened bread, perfect for any curry.',
    price: '2.00 AED',
  },
  {
    img: 'product3',
    alt: 'Alu Nan',
    name: 'Alu Nan',
    desc: 'A Pakistani nan bread stuffed with spiced potatoes, a favorite of many.',
    price: '4.00 AED',
  },
  {
    img: 'product3',
    alt: 'Zatar Nan',
    name: 'Zatar Nan',
    desc: 'A delightful Pakistani nan bread seasoned with zatar, a unique taste.',
    price: '4.00 AED',
  },
  {
    img: 'product3',
    alt: 'Chiken Cheez Nan',
    name: 'Chiken Cheez Nan',
    desc: 'A scrumptious Pakistani nan bread with chicken and cheese filling.',
    price: '8.00 AED',
  },
  {
    img: 'product3',
    alt: 'Roogni Nan',
    name: 'Roogni Nan',
    desc: 'A special Pakistani nan bread garnished with sesame seeds.',
    price: '3.0 AED',
  },
  {
    img: 'product3',
    alt: 'Sada Nan',
    name: 'Sada Nan',
    desc: 'A simple and soft Pakistani leavened bread, perfect for any curry.',
    price: '1.0 AED',
  },
  {
    img: 'product3',
    alt: 'Sada Roti',
    name: 'Sada Roti',
    desc: 'A traditional Pakistani unleavened flatbread, a staple in many meals.',
    price: '1.0 AED',
  },
  {
    img: 'product3',
    alt: 'Misri Roti',
    name: 'Misri Roti',
    desc: 'A sweet Pakistani flatbread made with sugar crystals, a delightful treat.',
    price: '4.0 AED',
  },
  {
    img: 'product3',
    alt: 'Halwa Poori',
    name: 'Halwa Poori',
    desc: 'A classic Pakistani breakfast combination, consisting of halwa and poori.',
    price: '1.0 AED',
  },
  {
    img: 'product3',
    alt: 'Beef Paya',
    name: 'Beef Paya',
    desc: 'A hearty Pakistani soup made with beef trotters, rich and flavorful.',
    price: '8.0 AED',
  },
  {
    img: 'product3',
    alt: 'Mutton Paya',
    name: 'Mutton Paya',
    desc: 'A delicious and nutritious Pakistani soup made with mutton trotters.',
    price: '10.0 AED',
  },
  {
    img: 'product3',
    alt: 'Kofta',
    name: 'Kofta Channa',
    desc: 'A Pakistani dish with spiced meatballs cooked in a flavorful sauce.',
    price: '10.0 AED',
  },
  {
    img: 'product3',
    alt: 'Haleem',
    name: 'Haleem',
    desc: 'A popular Pakistani dish made with meat, lentils, and wheat, slow-cooked to perfection.',
    price: '10.0 AED',
  },
  {
    img: 'product3',
    alt: 'Nehari',
    name: 'Nehari',
    desc: 'A traditional Pakistani stew made with slow-cooked meat and aromatic spices.',
    price: '12.0 AED',
  },
  {
    img: 'product3',
    alt: 'White Chana',
    name: 'White Chana',
    desc: 'A Pakistani dish made with tender chickpeas cooked in a spiced gravy.',
    price: '5.0 AED',
  },
  {
    img: 'product3',
    alt: 'Veg',
    name: 'Veg',
    desc: 'A hearty and nutritious Pakistani vegetarian dish, perfect for veggie lovers.',
    price: '6.0 AED',
  },
  {
    img: 'product3',
    alt: 'Chana Dal',
    name: 'Chana Dal',
    desc: 'A comforting Pakistani dish made with fried lentils and aromatic spices.',
    price: '6.0 AED',
  },
  {
    img: 'product3',
    alt: 'Egg Fry',
    name: 'Egg Fry',
    desc: 'A simple and delicious Pakistani egg dish, easy to prepare and delightful to eat.',
    price: '2.0 AED',
  },
  {
    img: 'product3',
    alt: 'Egg Omelette',
    name: 'Egg Omelette',
    desc: 'A classic omelette made with eggs and your choice of fillings, popular in Pakistan.',
    price: '2.0 AED',
  },
  {
    name: 'Namkeen Lassi',
    desc: 'Savory yogurt-based drink',
    price: '3.0 AED',
  },
  {
    name: 'Sweet Lassi',
    desc: 'Sweetened yogurt-based drink',
    price: '3.0 AED',
  },
  {
    name: 'Karak Chai',
    desc: 'Strong and flavorful tea',
    price: '1.0 AED',
  },
  {
    name: 'Dahi',
    desc: 'Plain yogurt',
    price: '4.0 AED',
  },
  {
    name: 'Maghaz',
    desc: 'Brain curry',
    price: '10.0 AED',
  },
  {
    name: 'Aloo Keema',
    desc: 'Minced meat and potato curry',
    price: '10.0 AED',
  },
  {
    name: 'Mutter Keema',
    desc: 'Minced meat and peas curry',
    price: '10.0 AED',
  },
];

export const productDataTwo = [
  {
    img: sweet1,
    alt: 'Rasgula',
    name: 'Rasgula',
    desc: 'A spongy and sweet dessert made from cottage cheese balls soaked in sugar syrup.',
    price: '1.0 AED',
    button: 'Order Now',
  },
  {
    img: sweet2,
    alt: 'Gulabjaman',
    name: 'Gulabjaman',
    desc: 'Delicious deep-fried dumplings soaked in fragrant sugar syrup.',
    price: '1.0 AED',
    button: 'Order Now',
  },
  {
    img: sweet3,
    alt: 'Barfi',
    name: 'Barfi',
    desc: 'A rich and creamy fudge-like sweet made with condensed milk and various flavors.',
    price: '1.0 AED',
    button: 'Order Now',
  },
  {
    img: sweet4,
    alt: 'Gajjar Halwa',
    name: 'Gajjar Halwa',
    desc: 'A delectable sweet dish made from grated carrots cooked in milk and sugar.',
    price: '10.0 AED',
    button: 'Order Now',
  },
  {
    img: sweet5,
    alt: 'Kheer',
    name: 'Kheer',
    desc: 'A classic rice pudding made with milk, rice, and sweetened with sugar or jaggery.',
    price: '5.0 AED',
    button: 'Order Now',
  },
  {
    img: sweet6,
    alt: 'Custard',
    name: 'Custard',
    desc: 'A creamy and smooth dessert made with milk, eggs, sugar, and flavored with vanilla.',
    price: '5.0 AED',
    button: 'Order Now',
  },
];

export const SnacksFastFood = [
  {
    name: 'Papri Chana Chaat',
    desc: 'A delightful combination of crispy papri, chickpeas, and tangy chutneys.',
    price: '5.0 AED',
  },
  {
    name: 'Dahi Poori',
    desc: 'Crispy puris filled with yogurt, spices, and chutneys.',
    price: '5.0 AED',
  },
  {
    name: 'Samosa Chaat',
    desc: 'Samosa pieces topped with yogurt, chutneys, and spices.',
    price: '5.0 AED',
  },
  {
    name: 'Dahi Bara',
    desc: 'Lentil-based dumplings soaked in yogurt and garnished with spices.',
    price: '5.0 AED',
  },
  {
    name: 'Pani Puri',
    desc: 'Hollow puris filled with flavored water, potatoes, and chutneys.',
    price: '5.0 AED',
  },
  {
    name: 'French Fries',
    desc: 'Crispy and seasoned potato fries.',
    price: '5.0 AED',
  },
  {
    name: 'Potato Samosa',
    desc: 'Crispy pastry filled with spiced potato filling.',
    price: '1.0 AED',
  },
  {
    name: 'Shami Kabab',
    desc: 'Minced meat patties seasoned with spices and herbs.',
    price: '2.50 AED',
  },
  {
    name: 'Chicken Patties',
    desc: 'Flaky pastry filled with flavorful minced chicken.',
    price: '2.0 AED',
  },
  {
    name: 'Roll Bread',
    desc: 'Flatbread wrapped around a filling of your choice.',
    price: '3.5 AED',
  },
  {
    name: 'Zinger Burger',
    desc: 'Crunchy chicken fillet burger with zesty flavors.',
    price: '8.0 AED',
  },
  {
    name: 'Shami Burger',
    desc: 'Burger with a shami kabab patty and condiments.',
    price: '6.0 AED',
  },
  {
    name: 'Tikka Burger',
    desc: 'Burger featuring flavorful chicken tikka as the patty.',
    price: '8.0 AED',
  },
  {
    name: 'Beef Burger',
    desc: 'Classic beef burger with juicy and savory flavors.',
    price: '6.0 AED',
  },
  {
    name: 'Chicken Grill Burger',
    desc: 'Grilled chicken burger with a delicious smoky taste.',
    price: '9.0 AED',
  },
  {
    name: 'Chicken Burger',
    desc: 'Chicken patty burger with a medley of toppings.',
    price: '6.0 AED',
  },
  {
    name: 'Chicken Shawarma Burger',
    desc: 'Burger with tender chicken shawarma and condiments.',
    price: '9.0 AED',
  },
  {
    name: 'BBQ Pizza Special',
    desc: 'Pizza loaded with BBQ toppings for a flavorful experience.',
    price: '25 AED',
  },
  {
    name: 'Cream Chaat',
    desc: 'Creamy and delicious chaat with various toppings.',
    price: '5.0 AED',
  },
  {
    name: 'Ras Malai',
    desc: 'Delicate dessert made with soft cheese dumplings in sweet milk.',
    price: '5.0 AED',
  },
  {
    name: 'Falooda',
    desc: 'Refreshing dessert with vermicelli, rose syrup, and ice cream.',
    price: '7.0 AED',
  },
  {
    name: 'Kulfi',
    desc: 'Traditional Indian ice cream with rich flavors.',
    price: '3.0 AED',
  },
  {
    name: 'Special Kheer',
    desc: 'Rice pudding with nuts, saffron, and aromatic spices.',
    price: '5.0 AED',
  },
  {
    name: 'Custard',
    desc: 'Smooth and creamy dessert made from custard powder.',
    price: '5.0 AED',
  },
  {
    name: 'Rabri Dhooh',
    desc: 'Sweetened condensed milk dessert with a rich and creamy texture.',
    price: '5.0 AED',
  },
  {
    name: 'Royal Falooda',
    desc: 'Elegant dessert with layers of falooda, ice cream, and syrups.',
    price: '8.0 AED',
  },
  {
    name: 'Shahi Tukda',
    desc: 'Indian bread pudding flavored with cardamom and saffron.',
    price: '5.0 AED',
  },
];

export const BbqData = [
  {
    name: 'Chicken Tikka',
    desc: 'Tender pieces of chicken marinated and grilled to perfection.',
    price: '15.0 AED',
  },
  {
    name: 'Chicken Green Tikka',
    desc: 'Chicken tikka with a fresh and flavorful green marinade.',
    price: '15.0 AED',
  },
  {
    name: 'Chicken Malai Boti',
    desc: 'Creamy and succulent chicken boti cooked with spices.',
    price: '15.0 AED',
  },
  {
    name: 'Chicken Achari Tikka',
    desc: 'Chicken tikka marinated with tangy and spicy pickle flavors.',
    price: '15.0 AED',
  },
  {
    name: 'Chicken Seekh Kabab',
    desc: 'Skewered minced chicken kababs seasoned with aromatic spices.',
    price: '15.0 AED',
  },
  {
    name: 'Chicken Reshmi Kabab',
    desc: 'Delicate chicken kababs made with a silky marinade.',
    price: '15.0 AED',
  },
  {
    name: 'Chicken Grill Black',
    desc: 'Grilled chicken with a rich and smoky flavor.',
    price: '35.0 AED',
  },
  {
    name: 'Chicken Grill',
    desc: 'Classic grilled chicken with a delicious taste.',
    price: '35.0 AED',
  },
  {
    name: 'Chicken Piece',
    desc: 'Single piece of succulent chicken.',
    price: '7.0 AED',
  },
  {
    name: 'Chicken Fried',
    desc: 'Crispy and flavorful fried chicken.',
    price: 'Best Buy',
  },
  {
    name: 'Chicken Chargha',
    desc: 'Whole marinated and deep-fried chicken.',
    price: '25.0 AED',
  },
  {
    name: 'Grill Fish',
    desc: 'Fresh fish grilled to perfection.',
    price: '15.0 AED',
  },
  {
    name: 'Mutton Chop BBQ',
    desc: 'Grilled mutton chops with a BBQ flavor.',
    price: '18.0 AED',
  },
  {
    name: 'Mutton Kabab',
    desc: 'Spiced and flavorful minced mutton kababs.',
    price: '15.0 AED',
  },
  {
    name: 'Mutton Tikka Boti',
    desc: 'Tender mutton pieces marinated and grilled.',
    price: '18.0 AED',
  },
  {
    name: 'Mutton Mandi',
    desc: 'Aromatic rice and mutton dish with Middle Eastern flavors.',
    price: '30.0 AED',
  },
  {
    name: 'Mutton Shuwa',
    desc: 'Slow-cooked mutton with traditional spices and flavors.',
    price: '30.0 AED',
  },
  {
    name: 'Bukhari Pulao',
    desc: 'Flavorful rice dish with a mix of spices and meat.',
    price: '30.0 AED',
  },
];
